<script>
import ParallaxBlock from '@/components/common/ParallaxBlock';
import LinkListItem from '@/components/common/LinkListItem';

export default {
  name: 'WhoIAmLinks',
  components: {
    ParallaxBlock,
    LinkListItem
  },
  props: {
    scrollY: {
      type: Number,
      default: 0
    },
    parallaxRatio: {
      type: Number,
      default: 0
    }
  }
};
</script>

<template lang="pug">
  ParallaxBlock.p-whoiam-links(
    :scrollY = 'scrollY'
    :parallaxRatio = 'parallaxRatio'
    )
    .p-whoiam-links__items
      LinkListItem.p-whoiam-links__item(
        href = 'https://instagram.com/wonedesignofficial'
        target = '_blank'
        )
        |Instagram
      LinkListItem.p-whoiam-links__item(
        href = 'https://wa.me/905308335364'
        target = '_blank'
        )
        |WhatsApp
      LinkListItem.p-whoiam-links__item(
        href = 'mailto:info@wonedesign.com'
        target = '_blank'
        )
        |info@wonedesign.com
      LinkListItem.p-whoiam-links__item(
        href = 'tel:+905308335364'
        target = '_blank'
        )
        |+90 530 833 53 64
      LinkListItem.p-whoiam-links__item(
        href = 'tel:+905511740282'
        target = '_blank'
        )
        |+90 551 174 02 82 
    .p-whoiam-links__items
      LinkListItem.p-whoiam-links__item(
        href = 'https://www.google.com/maps/place/WONEDESIGN+-+Dijital+Reklam+Ajansı/@37.8888276,32.4943816,15z/data=!4m5!3m4!1s0x0:0x6439ad2742f4e84a!8m2!3d37.8888276!4d32.4943816'
        )
        |Location on Maps
</template>

<style lang="scss">
.p-whoiam-links {
  @include l-more-than-mobile {
    width: math.div(500, 1360) * 100%;
    position: absolute;
    top: 270px;
    left: math.div(180, 1360) * 100%;
  }
  @include l-mobile {
    margin-bottom: 50px;
  }
  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }

  // Transition
  transition-property: opacity, transform;
  .view-enter &,
  .view-asc-enter & {
    opacity: 0;
  }
  .view-enter &,
  .view-asc-leave-to & {
    transform: translate3d(0, 100px, 0);
  }
  .view-asc-enter &,
  .view-leave-to & {
    transform: translate3d(0, -100px, 0);
  }
  .view-enter-to &,
  .view-asc-enter-to & {
    opacity: 1;
    transition-duration: 1s;
    transition-delay: 1.3s;
    transition-timing-function: $easeOutQuad;
  }
  .view-leave-to &,
  .view-asc-leave-to & {
    opacity: 0;
    transition-duration: 0.6s;
    transition-delay: 0.1s;
    transition-timing-function: $easeInQuad;
  }
}
</style>
