<script>
import { MathEx, sleep } from '@ykob/js-util';
import normalizeWheel from 'normalize-wheel';

import store from '@/store';
import WhoIAmSection from '@/components/who-i-am/WhoIAmSection.vue';
import WhoIAmHeading from '@/components/who-i-am/WhoIAmHeading.vue';
import WhoIAmThanks from '@/components/who-i-am/WhoIAmThanks.vue';
import WhoIAmLinks from '@/components/who-i-am/WhoIAmLinks.vue';

export default {
  name: 'WhoIAm',
  metaInfo: {
    title: 'Who We Are / '
  },
  components: {
    WhoIAmSection,
    WhoIAmHeading,
    WhoIAmThanks,
    WhoIAmLinks
  },
  data() {
    return {
      scrollY: 0,
      anchorY: 0,
      anchorYPrev: 0,
      clientHeight: 0,
      isRendering: false
    };
  },
  watch: {
    async '$store.state.resolution.y'() {
      await sleep(10);
      this.resize();
    }
  },
  computed: {
    styles() {
      return {
        paddingTop: `${this.$store.state.resolution.y / 2}px`,
        transform: `translate3d(0, ${-this.scrollY}px, 0)`
      };
    }
  },
  beforeRouteEnter(to, from, next) {
    store.commit('transit', {
      globalId: 50
    });
    next();
  },
  created() {
    window.addEventListener('wheel', this.wheel, { passive: false });
    window.addEventListener('touchstart', this.touchstart);
    window.addEventListener('touchmove', this.touchmove);
    this.scrollY = 0;
    this.anchorY = 0;
    this.anchorYPrev = 0;
    this.$store.commit('setScrollProgress', 0);
  },
  async mounted() {
    const { commit } = this.$store;

    commit('changeBackground', {
      isHome: false,
      hasDelay: false
    });
    commit('showHomeObjs', false);
    commit('showWorksObjs', {
      index: 0,
      direction: 1
    });
    commit('showWhoIAmObjs', true);
    await sleep(500);
    commit('showUI');
    this.isRendering = true;
    this.resize();
    this.update();
  },
  destroyed() {
    window.removeEventListener('wheel', this.wheel, { passive: false });
    window.removeEventListener('touchstart', this.touchstart);
    window.removeEventListener('touchmove', this.touchmove);
    this.isRendering = false;
  },
  methods: {
    update() {
      const { state, commit } = this.$store;

      this.scrollY =
        Math.floor((this.scrollY + (this.anchorY - this.scrollY) / 10) * 100) /
        100;
      commit(
        'setScrollProgress',
        this.scrollY / (this.clientHeight - state.resolution.y)
      );
      if (this.isRendering === true) {
        requestAnimationFrame(this.update);
      }
    },
    wheel(e) {
      e.preventDefault();

      const n = normalizeWheel(e);
      const { state, commit } = this.$store;

      if (state.isWheeling === true) return;

      if (this.scrollY < 1 && n.pixelY < 0) {
        // Go to the previous page.
        commit('startWheeling');
        this.$router.push(`/works/${state.works[state.works.length - 1].key}/`);
      } else {
        // Scroll the content of the current page.
        this.anchorY = MathEx.clamp(
          this.anchorY + n.pixelY,
          0,
          this.clientHeight - state.resolution.y
        );
      }
    },
    touchstart() {
      this.anchorYPrev = this.anchorY;
    },
    touchmove() {
      const { state, commit, dispatch } = this.$store;

      if (state.isTouchMoving === true) {
        if (this.scrollY < 1 && state.touchMove.y > 10) {
          // Go to the previous page.
          dispatch(
            'debounceRouterPush',
            `/works/${state.works[state.works.length - 1].key}/`
          );
          commit('touchEnd');
        } else {
          // Scroll the content of the current page.
          this.anchorY = MathEx.clamp(
            this.anchorYPrev - state.touchMove.y * 1.5,
            0,
            this.clientHeight - state.resolution.y
          );
        }
      }
    },
    resize() {
      const { state, commit } = this.$store;

      this.clientHeight = this.$refs['whoiam-wrap'].clientHeight;
      this.anchorY = MathEx.clamp(
        this.anchorY,
        0,
        this.clientHeight - state.resolution.y
      );
      commit(
        'setScrollProgress',
        this.scrollY / (this.clientHeight - state.resolution.y)
      );
    }
  }
};
</script>

<template lang="pug">
.p-view-wrap
  .p-whoiam-wrap(
    :style = 'styles'
    ref = 'whoiam-wrap'
    )
    .p-whoiam-wrap__in
      WhoIAmHeading
      WhoIAmSection(
        :num = '1'
        :scrollY = 'scrollY'
        :parallaxRatio = '0.1'
        )
        h2
          |Unique &amp; Creative
        p
          |WONEDESIGN is a digital agency established to meet the digital needs of brands such as search engine optimization, google and yandex ads, web development, digital marketing, UI, UX, graphic design and brand identity.
          |In line with these services, our agency had the opportunity to help building projects for many companies.
          |Our goal is to connect people with brands and bussiness thruogh design. A combination of strong concepts and refind execution to deliver premium results. Basically we like create cool shit.
        p
          |We are always available for interesting collobrations and fun side projects. If you would like to work together, feel free to get in touch.
          br
          br
        h2
          |WHAT WE OFFER
        p
          |Web Design &amp; Development
        p
          |Corporate Identity and Logo Design
        p
          |Social Media Management
        p
          |Google &amp; Yandex Ads
        p
          |Search Engine Optimization
        p
          |Production
        p
          |Customized Softwares
          br
          br
      WhoIAmSection(
        :num = '2'
        )
        h2
          |CERTIFICATES WE'VE RECIEVED
        p
          |introduction to business management certificate of achievement
        p
          |Certificate of Wordpress Expertise
        p
          |Certificate of Completion HTML,CSS,JS &amp; JQUERY Online Course
        p
          |Certificate of Building an Immersive Creative Website from Scratch without Frameworks
        p
          |certificate of complete web design from figma to webflow to freelancing
        p
          |certificate of create award winning animation and interaction design in webflow
        p
          |certificate of creating a simple portfolio website with webgl and barba.js
        p
          |certificate of figma user interface design essentials ui &amp; ux design
        p
          |Certificate of Making a Difference with Correct Marketing Communication
        p
          |Certificate of General Occupational Health and Safety
        p
          |Certificate of foreign trade
        p
          |Certificate of personal stress management
        p
          |Certificate of Complaint Management
        p
          |Certificate of Dealing with Difficult People
        p
          |Technology and Efficiency Key Innovative Companies Training Certificate
        p
          |Innovation training certificate
        p
          |Certificate of Teaching with Technology (e-Teach)
        p
          |Certificate of 3D Modeling with Autodesk 123D
        p
          |Certificate of CAD / CAM Fundamentals
        p
          |Seminar Participation Certificate of "Branding Your Idea and Business , Entrepreneurship" 
        p
          |Seminar Participation Certificate of "Self-Confidence and Border Boots"
        p
          |Seminar Participation Certificate of "Psychological Resilience and Emotion Management"
      
      WhoIAmSection(
        :num = '3'
        :scrollY = 'scrollY'
        :parallaxRatio = '0.1'
        )
        h2
         |CONTACT
        p
          |T: +90 530 833 53 64
        p
          |T: +90 551 174 02 82
        p
          |E: info@wonedesign.com
        p
          |A: Musalla Bağları Mahallesi, Kule Caddesi, Selçuklu Kulesi Sitesi, No:2, Kat:20, No:31, 42060 Selçuklu / Konya

      WhoIAmLinks(
        :scrollY = 'scrollY'
        :parallaxRatio = '0.05'
        )
      WhoIAmThanks
</template>

<style lang="scss">
.p-whoiam-wrap {
  @include l-more-than-mobile {
    margin-right: 7.5%;
    margin-left: 7.5%;
    padding-bottom: 300px;
  }
  @include l-mobile {
    margin-right: 44px;
    margin-left: 44px;
    padding-bottom: 44px;
  }
  &__in {
    position: relative;
    margin-top: -25px;
  }
}
</style>
