<script>
export default {
  name: 'HomeHeading'
};
</script>

<template lang="pug">
h1.p-home-heading
    |WONEDESIGN
    br
    |Unique &amp; Creative
</template>

<style lang="scss">
.p-home-heading {
  display: none;
}
</style>
