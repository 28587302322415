export default [
  {
    key: 'joseph-berry',
    title: 'JOSEPH BERRY',
    shortTitle: 'JBMC',
    description: 'Category: PORTFOLIO',
    credit:
      'WEB DESIGN & DEVELOPMENT\n( MASTERCLASS )\nDate: 2021\nDeveloper : WONEDESIGN',
    href: 'https://joseph-berry-webflow-master-class.webflow.io/',
    textWidth: 390
  },
  {
    key: 'canpolat-mimarlik',
    title: 'Canpolat MİMARLIK',
    shortTitle: 'Canpolat',
    description: 'Category: ARCHITECTURE',
    credit:
      'WEB DESIGN & DEVELOPMENT\nLOGO DESIGN\nDate: 2021\nDeveloper : WONEDESIGN',
    href: 'https://canpolatmimarlik.com/',
    textWidth: 1260
  },
  {
    key: 'a90',
    title: 'A90 YAPI',
    shortTitle: 'A90 YAPI',
    description: 'Category: CCONSTRUCTION',
    credit: 'WEB DESING & DEVELOPMENT\nDate: 2021\nDeveloper : WONEDESIGN',
    href: 'https://a90yapi.com',
    textWidth: 940
  },
  {
    key: 'nosi-insaat',
    title: 'NOSİ MİMARLIK',
    shortTitle: 'NOSİ',
    description: 'Category: ARCHITECTURE',
    credit:
      'WEB DESIGN & DEVELOPMENT\nLOGO DESIGN\nDate: 2021\nDeveloper : WONEDESIGN',
    href: 'https://nosiinsaatmimarlik.com/',
    textWidth: 1260
  },
  {
    key: 'parsiyel-net',
    title: 'PARSİYEL.NET',
    shortTitle: 'PARSİYEL',
    description: 'Category: LOGISTICS',
    credit: 'WEB DESIGN & DEVELOPMENT\nDate: 2022\nDeveloper : WONEDESIGN',
    href: 'http://parsiyel.net',
    textWidth: 390
  },
  {
    key: 'ozbekistan-tasimacilik',
    title: 'ÖZBEKİSTAN TAŞIMACILIK',
    shortTitle: 'ÖZBEKİSTAN',
    description: 'Category: LOGISTICS',
    credit: 'WEB DESIGN & DEVELOPMENT\nDate: 2022\nDeveloper : WONEDESIGN',
    href: 'http://ozbekistantasimacilik.net',
    textWidth: 390
  },
  {
    key: 'airland',
    title: 'AIRLAND HAVACILIK',
    shortTitle: 'AIRLAND',
    description: 'Category: AVIATION',
    credit:
      'WEB DESIGN & DEVELOPMENT\nSOCIAL MEDIA MANAGEMENT\nLOGO DESIGN\nDate: 2022\nDeveloper : WONEDESIGN',
    href: 'https://airlandhavacilik.com',
    textWidth: 1720
  },
  {
    key: 'sebil',
    title: 'SEBİL TRANSPORT',
    shortTitle: 'SEBİL',
    description: 'Category: LOGISTICS',
    credit: 'SEARCH ENGINE OPTIMIZATION\nDate: 2022\nDeveloper : WONEDESIGN',
    href: 'https://sebiltransport.com',
    textWidth: 390
  },
  {
    key: 'duybeni',
    title: 'DUY BENİ İŞİTME',
    shortTitle: 'DUYBENİ',
    description: 'Category: MEDICAL',
    credit:
      'WEB DESIGN & DEVELOPMENT\nLOGO DESIGN\nDate: 2022\nDeveloper : WONEDESIGN',
    href: 'https://duybeniisitmecihazlari.com',
    textWidth: 390
  },
  {
    key: 'onur-yuktasir',
    title: 'ONUR YÜKTAŞIR',
    shortTitle: 'ONRYKTSR',
    description: 'Category: PORTFOLIO',
    credit: 'WEB DESIGN & DEVELOPMENT\nDate: 2020\nWONEDESIGN',
    href: 'https://onuryuktasir.pages.dev',
    textWidth: 390
  }
];
